.App {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
}

.contentWrapper {
  position: relative;
  max-width: 1156px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1203px) {
  .contentWrapper {
    margin: 0 32px;
    width: 100%;
  }
}
