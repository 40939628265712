.main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    justify-content: center;
    max-width: 450px;
}

.logo {
    align-self: center;
}

.textContent {
    font-weight: normal;
}

.buttonsContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
