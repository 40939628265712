@font-face {
    font-family: 'Stem';
    font-style:  normal;
    font-weight: 400;
    src:
            url('./Stem-Regular.ttf') format('ttf'),
            url('./Stem-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Stem';
    font-style: normal;
    font-weight: 500;
    src:
            url('./Stem-Bold.ttf') format('ttf'),
            url('./Stem-Bold.woff2') format('woff2');
}