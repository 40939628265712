.button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 10px;
    border: none;
    outline: none;
    min-height: 48px;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
}

.button:disabled {
    opacity: 0.8;
    cursor: default;
}

.button:hover {
    opacity: 0.8;
}

.primary {
    color: var(--secondary);
    background-color: var(--primary);
}

.secondary {
    color: var(--primary);
    background-color: var(--secondary);;
}
