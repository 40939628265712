.content {
    position: relative;
    width: 100%;
}

.iconWrapper {
    position: absolute;
    left: 10px;
    height: 20px;
    width: 20px;
}